import { Button, Card, Col, Container, Form, Row, Table } from "react-bootstrap"
import { ThreeDotSpinner } from "../loader"
import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { aiService } from "../../service/api.service";

function KnowledgeBase() {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(null)
    const [file, setFile] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const page_size = 10

    function handlePageChange(pageNumber) {
        setPage(pageNumber);
    }

    useEffect(() => {
        getList()
    }, [])

    async function getList() {
        try {
            setLoader(true)
            const users = await aiService.listKnowledgeBase({ limit: page_size, page })
            setData(users.data.data)
            setTotalItems(users.data.total_count)
            setLoader(false)
        } catch (error) {
            setLoader(false)
            if (error?.response?.status == 401) {
                swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
                    localStorage.clear()
                    window.location.href = "/";
                });
            }
        }
    }

    async function addKnowledge() {
        if (file) {
            setLoader(true)
            try {
                let form = new FormData()
                form.append('files', file)
                const add = await aiService.addKnowledgeBase(form)
                swal({ icon: "success", text: "File uploaded." })
                await getList()
                setLoader(false)
            } catch (e) {
                setLoader(false)
                swal({ icon: 'error', text: e?.response?.data?.message ? e?.response.data.message : e?.message, button: "OK" })
            }
        } else {
            setLoader(false)
            swal({ icon: 'error', text: 'Please upload a file.' })
            return;
        }
    }

    return (
        <>
            <div className="dashboard-main-area">
                {loader ? <ThreeDotSpinner /> : ""}
                <Container fluid>
                    <div className="dashboard-area-heading management-heading">
                        <Row className="row mb-3">
                            <div className="col-8 ms-4">
                                <h2 className="align-self-center m-0">Knowledge Base</h2>
                            </div>
                        </Row>
                        <Row className="mb-4 justify-content-center">
                            <Col md={11}>
                                <div>
                                    <Card>
                                        <Card.Body>
                                            <h4>Upload File</h4>
                                            <p>Upload your files here.</p>
                                            <Form>
                                                <Form.Group className="d-flex">
                                                    <input className="form-control w-50 ps-4" accept=".pdf, .doc" type="file" onChange={(e) => setFile(e.target.files[0])} />
                                                    <Button className="btn-success ms-2" onClick={() => addKnowledge()}>Submit</Button>
                                                </Form.Group>
                                            </Form>
                                        </Card.Body>
                                    </Card>

                                </div>
                            </Col>
                        </Row>
                        <Row className="pb-4 justify-content-center">
                            <Col md={11}>
                                <div className="audit-request-box">
                                    <Table responsive="sm" className="UserListTable">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.map(r => (
                                                    <tr>
                                                        <td>{r.filename}</td>
                                                        <td>{r.status}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </Table>
                                    <Pagination
                                        activePage={page}
                                        itemsCountPerPage={page_size}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                        prevPageText={"Prev"}
                                        nextPageText={"Next"}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default KnowledgeBase