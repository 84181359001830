import axios from 'axios';
import swal from "sweetalert";

export default function setupAxiosInterceptors () {

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.clear();
        window.location.href = '/'
      } else {
        swal({ icon: "error", text: error.response.data.message })
      }
      return Promise.reject(error);
    }
  );
};
