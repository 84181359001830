import { Col, Container, Row, Button, Form, ListGroup, Modal, Tab, Tabs, Card } from "react-bootstrap"
import { ThreeDotSpinner } from "../loader"
import { useEffect, useState } from "react";
import swal from "sweetalert";
import { Formik, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { aiService } from "../../service/api.service";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Swal from "sweetalert2"
import PhoneModel from "./Model";
import PhoneVoice from "./PhoneVoice";
import Analysis from "./Analysis";
import Transcriber from "./Transcriber";

function PhoneNumbers() {
  const [loader, setLoader] = useState(false);
  const [importPhoneModal, setImportPhoneModal] = useState(false)
  const [selectedPhone, setSelectedPhone] = useState({})
  const [selectedAgent, setSelectedAgent] = useState("")
  const [phoneNumbers, setPhoneNumbers] = useState([])
  const [agents, setAgents] = useState([])
  const [delPh, setDelPh] = useState(0)
  const [tabKey, setTabKey] = useState('home');

  useEffect(() => {
    getConfig()
  }, [delPh])

  async function getConfig() {
    try {
      setLoader(true)
      let phones = await aiService.phoneNumbers()
      setAgents(phones.data.agents)
      phones = phones.data.data
      if (phones.length) {
        setSelectedPhone(phones[0])
        if (phones[0]?.agent) {
          setSelectedAgent(phones[0].agent)
        }
      }
      setPhoneNumbers(phones)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  const configValidation = Yup.object().shape({
    phoneNumber: Yup.string().trim().required('Phone Number is required')
      .test('valid-phone', 'Phone number is not valid', function (value) {
        if (!value) return false;
        const phoneNumber = parsePhoneNumberFromString(value);
        return phoneNumber && phoneNumber.isValid();
      }),
    twilioSid: Yup.string().trim().required('Twilio Account SID  is required'),
    authToken: Yup.string().trim().required('Twilio Auth Token is required'),
    label: Yup.string().trim().required('Label for Phone Number is required'),
  });

  async function deletePhone(phone_id) {
    if (!phone_id) {
      swal({ icon: 'error', text: "No phone selected", button: "OK" })
      return;
    }
    Swal.fire({
      text: "Are you sure you want to delete the phone number?",
      showCancelButton: true
    }).then(async r => {
      if (r.isConfirmed) {
        try {
          setLoader(true)
          await aiService.deletePhoneNumber(phone_id)
          setLoader(false)
          swal({ icon: 'success', text: 'Phone Number deleted successfully.' })
          setDelPh(delPh + 1)
        } catch (error) {
          setLoader(false)
          if (error?.response?.status == 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
            });
          } else {
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
          }
        }
      }
    })

  }

  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <div className="dashboard-area-heading management-heading">
        <Row className="row mb-3">
          <div className="col-12">
            
            <h2 className="align-self-center">Phone Number Configuration <img src={require("../../assets/images/icons8-call.gif")} /></h2>
          </div>
        </Row>
        <Row className="">
          <Col lg={4} xxl={3}>
            <div className="availabilit-section phone-number-config-left">
              <Button className="btn-success w-100" onClick={() => setImportPhoneModal(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-1" viewBox="0 0 16 16">
                  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                </svg>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>Import from twilio</span>
              </Button>
              <ListGroup activeKey={`#${selectedPhone._id}`} className="mt-3">
                {
                  phoneNumbers.map((a, i) => (
                    <ListGroup.Item action className="my-1 agent-list-name d-flex justify-content-between align-items-center" href={"#" + a._id} onClick={() => setSelectedPhone(a)}>
                      <div>
                        <h6 className="mb-0 pb-0 call-text">{a.phone}</h6>
                        <small className="call-text-country">{a.label}</small>
                      </div>
                      <Button size="sm" variant="danger" onClick={() => deletePhone(a._id)}>
                        <svg className="text-danger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                          <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                        </svg>
                      </Button>
                    </ListGroup.Item>
                  ))
                }
              </ListGroup>
            </div>
          </Col>
          <Col lg={8} xxl={9}>
            <div className="availabilit-section input-w-100 me-3 under-phone phone-number-config-right">
              {
                selectedPhone?.phone ?
                  <>
                    <Tabs
                      id="uncontrolled-tab-example"
                      className="mb-3"
                      activeKey={tabKey}
                      onSelect={(k) => setTabKey(k)}
                    >
                      <Tab eventKey="home" title="Model">
                        <PhoneModel agents={agents} selectedPhone={selectedPhone} setSelectedAgent={setSelectedAgent} setLoader={setLoader} selectedAgent={selectedAgent} getConfig={getConfig} />
                      </Tab>
                      {/* <Tab eventKey="transcriber" title="Transcriber">
                        <Transcriber selectedPhone={selectedPhone} setLoader={setLoader} getConfig={getConfig} />
                      </Tab> */}
                      <Tab eventKey="profile" title="Voice">
                        <PhoneVoice selectedPhone={selectedPhone} setLoader={setLoader} getConfig={getConfig} />
                      </Tab>
                      <Tab eventKey="analysis" title="Analysis">
                        <Analysis selectedPhone={selectedPhone} setLoader={setLoader} getConfig={getConfig} />
                      </Tab>
                    </Tabs>
                  </>
                  :
                  <div className="no-files-added">
                    <img src={require("../../assets/images/download.png")} />
                    <h5 className="text-center">Kindly import a Phone Number</h5>
                  </div>
              }
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={importPhoneModal} onHide={() => setImportPhoneModal(false)} backdrop="static" centered className="import-phone-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            Import Phone Number
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize={true}
            initialValues={{
              phoneNumber: '',
              twilioSid: '',
              authToken: '',
              label: '',
              phone: ''
            }}
            validationSchema={configValidation}
            onSubmit={async (values, { setSubmitting }) => {
              setSubmitting(false);
              try {
                setLoader(true)
                await aiService.importPhoneNumber(values)
                setLoader(false)
                swal({ icon: "success", text: "Phone Number imported successfully." })
                setImportPhoneModal(false)
                await getConfig()
              } catch (err) {
                setLoader(false)
                console.log(err.response?.data?.message)
              }

            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => {
              return (
                <Form onSubmit={handleSubmit}>

                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>Twilio Phone Number
                        </Form.Label>
                        <Field name="phoneNumber">
                          {({ field }) => (
                            <PhoneInput
                              country={'us'}
                              inputClass="w-100"
                              value={values.phoneNumber}
                              onChange={(phone, c, e, value) => {
                                setFieldValue('phone', phone)
                                setFieldValue('phoneNumber', value)
                              }}
                              inputProps={{
                                name: 'phoneNumber',
                                required: true,
                                autoFocus: true,
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="phoneNumber" component="div" className="text-danger" />
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <Form.Group className="mt-3">
                        <Form.Label>Twilio Account SID

                        </Form.Label>
                        <Form.Control
                          className="w-100 textarea"
                          name="twilioSid"
                          placeholder="Twilio Account SID"
                          value={values.twilioSid}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.twilioSid && !!errors.twilioSid}
                        />
                      </Form.Group>
                      <ErrorMessage name="twilioSid" component="div" className="text-danger" />

                    </Col>
                    <Col md={12}>
                      <Form.Group className="mt-3">
                        <Form.Label>Twilio Auth Token
                        </Form.Label>
                        <Form.Control
                          className="w-100 textarea"
                          name="authToken"
                          placeholder="Twilio Auth Token"
                          value={values.authToken}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.authToken && !!errors.authToken}
                        />
                      </Form.Group>
                      <ErrorMessage name="authToken" component="div" className="text-danger" />

                    </Col>
                    <Col md={12}>
                      <Form.Group className="mt-3">
                        <Form.Label>Label

                        </Form.Label>
                        <Form.Control
                          className="w-100 textarea"
                          name="label"
                          placeholder="Label for Phone Number"
                          value={values.label}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.label && !!errors.label}
                        />
                      </Form.Group>
                      <ErrorMessage name="label" component="div" className="text-danger" />

                    </Col>
                  </Row>

                  <Button type="submit" className="mt-4 btn-success px-4" disabled={isSubmitting}>
                    Submit
                  </Button>
                </Form>
              )
            }}
          </Formik>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default PhoneNumbers