import { Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import samples from "./samples.js"

function CreateAgent({ activeTab, setActiveTab, templateData, setTemplateData, agentName, setAgentName }) {

  function handleTemplate(name) {
    setActiveTab(name)
    let sampleAgent = samples.find(a => a.type == name)
    setAgentName(sampleAgent.name)
    setTemplateData(sampleAgent)
  }

  function handleChange(event) {
    setTemplateData(tData => ({ ...tData, [event.target.name]: event.target.value }))
  }

  return <>
    <Form>
      <Form.Group controlId="formAssistantName">
        <Form.Label>Assistant Name (This can be adjusted at any time after creation.)</Form.Label>
        <Form.Control type="text" placeholder="New Assistant" onChange={(e) => setAgentName(e.target.value)} value={agentName} />
      </Form.Group>
      <Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Welcome Message
            <OverlayTrigger
              // placement="right"
              delay={{ show: 100, hide: 400 }}
              overlay={<Tooltip>
                The first message that the assistant will say.
              </Tooltip>}
            >
              <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
              </svg>
            </OverlayTrigger>
          </Form.Label>
          <Form.Control
            className="w-100"
            name="welcomeMsg"
            placeholder="The first message that the assistant will say."
            value={templateData.welcomeMsg}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>System Prompt
            <OverlayTrigger
              delay={{ show: 100, hide: 400 }}
              overlay={<Tooltip>
                The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant.
              </Tooltip>}
            >
              <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
              </svg>
            </OverlayTrigger>

          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            className="w-100 textarea"
            name="systemPrompt"
            placeholder="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
            value={templateData.systemPrompt}
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Group>
      <Row>
      </Row>
      <Row className="mt-3">
        <Col sm={12} >
          <div className="template-description">
            <h5>Quickstart</h5>
            <div className="quickstart-templates">
              <Row>
                <Col md={6} onClick={() => handleTemplate('appointment')} className="mb-3">
                  <div className={`quickstart-card ${activeTab == 'appointment' ? 'active' : ''}`}>
                    <h6>Appointment Setter</h6>
                    <p>Designed for dental practices to demonstrate setting appointments.</p>
                  </div>
                </Col>
                <Col md={6} onClick={() => handleTemplate('customer')} className="mb-3">
                  <div className={`quickstart-card ${activeTab == 'customer' ? 'active' : ''}`}>
                    <h6>Customer Support</h6>
                    <p>A versatile template designed with emotional intelligence and technical knowledge.</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={6} onClick={() => handleTemplate('qa')} className="mb-3">
                  <div className={`quickstart-card ${activeTab == 'qa' ? 'active' : ''}`}>
                    <h6>Inbound Q/A</h6>
                    <p>An inbound call agent example designed for comprehensive support.</p>
                  </div>
                </Col>
                <Col md={6} onClick={() => handleTemplate('game')} className="mb-3">
                  <div className={`quickstart-card ${activeTab == 'game' ? 'active' : ''}`}>
                    <h6>Game NPC</h6>
                    <p>An assistant for demonstrating an in-game NPC.</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  </>
}

export default CreateAgent