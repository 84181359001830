import React, { useEffect } from "react";
import { Container, Row, Col, Table, Button, Modal, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useState } from "react";
import Pagination from "react-js-pagination";
import { ThreeDotSpinner } from "../loader";
import { aiService } from "../../service/api.service";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { CheckCalendar } from 'react-check-calendar';
import 'react-check-calendar/dist/index.css';
import moment from "moment";
export const AvailibilitySchedule = () => {
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1)
  const [viewnote, setViewNote] = useState(false)
  const [noteData, setnoteData] = useState("")
  const handelCloseViewNote = () => setViewNote(false);
  const [dataoptions, setdataoptios] = useState([])
  const [appointmentUpdate, setappointmentUpdate] = useState(false);
  const handelCloseUpdateModal = () => setappointmentUpdate(false);
  const [updateData, setupdateData] = useState()
  const options = [
    {
      label: "Monday",
      value: "Mon",
      available: true,
      from: "09:00",
      to: "20:00"
    }, {
      label: "Tuesday",
      value: "Tue",
      available: true,
      from: "09:00",
      to: "20:00"
    }, {
      label: "Wednesday",
      value: "Wednesday",
      available: true,
      from: "09:00",
      to: "20:00"
    }, {
      label: "Thursday",
      value: "Thursday",
      available: true,
      from: "09:00",
      to: "20:00"
    }, {
      label: "Friday",
      value: "Friday",
      available: true,
      from: "09:00",
      to: "20:00"
    }, {
      label: "Saturday",
      value: "Saturday",
      available: true,
      from: "09:00",
      to: "20:00"
    },
    {
      label: "Sunday",
      value: "Sunday",
      available: true,
      from: "09:00",
      to: "20:00"
    }
  ];
  const tooltip = (
    <Tooltip id="tooltip">
      <p className="m-0 p-0">Edit</p>
    </Tooltip>
  );
  const updatetime = (val, e) => {
    let params = {
      _id: updateData._id,
      date: updateData.date,
      time: val
    }
    setupdateData(params)
  }
  useEffect(() => {
    get_availibility()
  }, [])
  async function get_availibility() {
    setLoader(true)
    try {

      // const params = show ? { reason: deactivatedReason } : {}

      // const response=await aiService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await aiService.get_availibility()
      if (response.status == 200) {
        console.log(response.data)
        setdataoptios(response.data.data)
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {


        })

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  function OnchangeFromtime(timefrom, label) {
    options.filter((obj, i) => {
      console.log(obj.label)
      console.log(label)
      if (obj.label === label) {
        console.log(dataoptions[i])
        dataoptions[i].available_from = timefrom
        setdataoptios([
          ...dataoptions
        ]);
        console.log(obj)
        setupdateData(dataoptions[i])
      }
    })

    console.log(options)

  }
  function OnchangeTotime(timefrom, label) {
    options.filter((obj, i) => {
      if (obj.label === label) {

        dataoptions[i].available_to = timefrom
        setdataoptios([
          ...dataoptions
        ]);
        setupdateData(dataoptions[i])
      }
    })

    console.log(options)

  }
  function Onchangeavailability(available, label, id) {
    options.filter((obj, i) => {

      if (obj.label === label) {
        dataoptions[i].available = available
        setdataoptios([
          ...dataoptions
        ]);
        save_availibility(dataoptions[i], id, false)
      }
    })
    console.log("=======", dataoptions)


  }
  async function save_availibility(data, id, update) {
    setLoader(true)
    try {
      let mydta = {
        available: data.available
      }
      if (update) {
        mydta.available_to = data.available_to;
        mydta.available_from = data.available_from;


      }
      // const params = show ? { reason: deactivatedReason } : {}

      // const response=await aiService.ChangeStatus(show ? (userId,params):(id,params))
      const response = await aiService.save_availibility(mydta, id)
      if (response.status == 200) {
        if (update) {
          handelCloseUpdateModal()
        }
        get_availibility()
        setLoader(false)
        swal("Success", response?.data?.message, "success").then(() => {


        })

      }
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        })
      } else {
      }
    }
  }
  function updateAvailablity(data) {
    setupdateData(data)
    setappointmentUpdate(true)

  }
  return (
    <>
      <div className="dashboard-main-area">
        {loader == true ? <ThreeDotSpinner /> : ""}

        <Container fluid>
          <div className="dashboard-area-heading management-heading">

            <Row className="row justify-content-center align-items-center mb-3">
              <Col md={11}>
                <div className="heading-top-area">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Availability Schedule</h2>
                  </div>
                  {/* <div className="heading-top-area-right">
                                                                      <div className="export-csv-btn">
                                                                             <Button type="button" variant="unset">Export CSV</Button>
                                                                      </div>
                                                               </div> */}
                </div>
              </Col>
            </Row>
            <Row className="pb-4 justify-content-center">
              <Col md={11}>
                <div className="availabilit-section">
                  {/* <div className="audit-request-box"> */}


                  <b>Days of Week</b>
                  {/* <div className="select-container"> */}


                  <>
                    {' '}

                    {dataoptions.map(option => {
                      return (
                        <div className="options_data row mt-3">
                          <Col md={4}>
                            <Form.Check
                              inline
                              label={option.available_day}
                              name={option.available_day}
                              type="checkbox"
                              id={`inline-checkbox-${option}`}
                              checked={option.available}
                              onChange={(e) => Onchangeavailability(e.target.checked, option.available_day, option._id)}
                            />
                          </Col>
                          <Col md={3}>
                            <div className="d-flex align-items-center">
                              From :<input className="form-control ms-2 width-fit" id={"From" + option} type="time" dateFormat="hh:mm A" value={option.available_from} onChange={(e) => OnchangeFromtime(e.target.value, option.available_day)} />
                              {' '}</div>
                          </Col>
                          <Col md={3}>
                            <div className="d-flex align-items-center">
                              To :<input className="form-control ms-2 width-fit" id={"To" + option} type="time" dateFormat="hh:mm A" value={option.available_to} onChange={(e) => OnchangeTotime(e.target.value, option.available_day)} />
                              <br></br>
                              {' '}</div>
                          </Col>
                          <Col md={2}>
                            <div className="d-flex align-items-center">
                            <OverlayTrigger placement="top" overlay={tooltip} >
                              <div className="edit-pencil">
                                <i className="fa fa-pencil" style={{ cursor: "pointer" }} onClick={() => updateAvailablity(option)}></i>
                              </div>
                              </OverlayTrigger>
                              {' '}</div>
                          </Col>
                        </div>
                      );
                    })}


                  </>


                  {/* </div> */}


                </div>
                {/* </div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Modal show={viewnote} onHide={handelCloseViewNote} backdrop="static"

        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Key Notes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3">
          <p>
            {noteData}
          </p>
          <p>
            {noteData}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handelCloseViewNote}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={appointmentUpdate} onHide={handelCloseUpdateModal} backdrop="static" aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update availibilty
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pb-3">
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Date</Form.Label>
              <input className="form-control ms-2" type="time" dateFormat="hh:mm A" value={updateData ? updateData.available_from : ""} onChange={(e) => OnchangeFromtime(e.target.value, updateData.available_day)} />
              {/* <span className="text-danger">{oldpassworderror}</span> */}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Time</Form.Label>
              <input className="form-control ms-2" type="time" dateFormat="hh:mm A" value={updateData ? updateData.available_to : ""} onChange={(e) => OnchangeTotime(e.target.value, updateData.available_day)} />
              {/* <span className="text-danger">{oldpassworderror}</span> */}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handelCloseUpdateModal} className="close-btn btn-secondary">Close</Button>
          <Button onClick={() => save_availibility(updateData, updateData._id, true)}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}