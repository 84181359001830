import { useEffect, useState } from "react"
import { Table, Container, Row, Col, Button, Modal, Offcanvas } from 'react-bootstrap';
import { aiService } from "../../service/api.service";
import { ThreeDotSpinner } from "../loader";
import swal from "sweetalert";
import Pagination from "react-js-pagination";

export function CallList() {
  const [calls, setCalls] = useState([])
  const [page, setPage] = useState(1)
  const [summary, setSummary] = useState("")
  const [summaryModal, setSummaryModal] = useState(false)
  const [totalItems, setTotalItems] = useState(1)
  const [filter, setFilter] = useState("")
  const [loader, setLoader] = useState(false);
  const [showLogCanvas, setLogCanvas] = useState(false);
  const [eventLog, setEventLog] = useState([])
  const page_size = 10

  useEffect(() => {
    getCallList()
  }, [page, filter])

  async function getCallList() {
    try {
      setLoader(true)
      const params = {
        page: page,
        limit: page_size,
        filter: filter
      }
      const list = await aiService.callListGpt(params)
      setCalls(list.data.data)
      setTotalItems(list.data.total_items)
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  function handlePageChange(pageNumber) {
    setPage(pageNumber);
  }

  async function makeCall(phone, phone_id, agent) {
    try {
      if (!phone) {
        swal({ icon: "warning", text: "Kindly enter phone number." })
        return;
      }
      setLoader(true)
      await aiService.makeCall({ phone, phone_id, agent })
      setLoader(false)
      swal({ icon: "success", text: "Call created successfully." })
      setPage(page)
      await getCallList()
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        if (error.response) {
          swal({ icon: "error", text: error.response.data.message })
        } else {
          swal({ icon: "error", text: error })
        }
      }
    }
  }

  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <div className="call-list-head">
            <h2 className="align-self-center m-0">Call List <img src={require("../../assets/images/icons8-call.gif")} /></h2>
            <div>
              <label htmlFor="">Call Type</label>
              <select name="filter" id="id_filter" className="form-control mb-3" onChange={(e) => setFilter(e.target.value)}>
                <option value="" selected={filter == ""}>All</option>
                <option value="outbound-api" selected={filter == "outbound-api"}>Outbound</option>
                <option value="inbound" selected={filter == "inbound"}>Inbound</option>
              </select>
            </div>
          </div>
          <Row className="pb-4 justify-content-center">
            <Col md={12}>
              <div className="audit-request-box">
                {
                  calls.length ?
                    <>
                      <Table responsive="sm" className="UserListTable">
                        <thead>
                          <tr>
                            <th>Type</th>
                            <th>From Number</th>
                            <th>To Number</th>
                            <th>Agent</th>
                            <th>Duration</th>
                            <th>Start Time (UTC)</th>
                            <th>End Time (UTC)</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {calls.map((call, index) => (
                            <tr key={index}>
                              <td>{call.type == 'inbound' ? 'Inbound' : 'Outbound'}</td>
                              <td>{call.from_number[0] != '+' ? '+' + call.from_number : call.from_number}</td>
                              <td>{call.to_number[0] != '+' ? '+' + call.to_number : call.to_number}</td>
                              <td>{call.agent}</td>
                              <td>{call.call_duration ? `${call.call_duration} seconds` : "0 seconds"} </td>
                              <td>{call.start_time}</td>
                              <td>{call.end_time}</td>
                              <td>{call.status}</td>
                              <td>
                                <Button variant="unset" className="call-back-btn" onClick={() => makeCall(call.type == 'inbound' ? call.from_number.replace("+", "") : call.to_number, call.phone_id, call.agent_id)}>Call back</Button>
                                {
                                  call.events?.length ? <Button variant="unset" className="view-logs-btn ms-1" onClick={() => {
                                    setLogCanvas(true)
                                    setEventLog(call.events)
                                  }}>View logs</Button>
                                    : ""
                                }
                                {
                                  call.summary && (
                                    <Button variant="unset" className="view-summary-btn ms-1" onClick={() => {
                                      setSummary(call.summary)
                                      setSummaryModal(true)
                                    }}>View Summary</Button>
                                  )
                                }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      <Pagination
                        activePage={page}
                        itemsCountPerPage={page_size}
                        totalItemsCount={totalItems}
                        pageRangeDisplayed={5}
                        onChange={(e) => handlePageChange(e)}
                        prevPageText={"Prev"}
                        nextPageText={"Next"}
                      />
                    </>
                    :
                    <div className="no-data-mid-area">
                      <img src={require("../../assets/images/no-data.gif")} />
                      <h4 className="availabilit-section text-center">No data found</h4>
                    </div>

                }
              </div>
            </Col>
          </Row>
        </div>
      </Container>

      <Offcanvas
        show={showLogCanvas}
        onHide={() => {
          setLogCanvas(false)
        }}
        placement="end"
        className="call-log-canvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Call Logs <img src={require("../../assets/images/call-log-icon.gif")} /></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table>
            <tbody>
              {
                eventLog.map(e => (
                  <tr>
                    <td className="call-timing">{e.time}</td>
                    <td className="call-message"><p>{e.message}</p><span>View More</span></td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal show={summaryModal} onHide={() => {
        setSummaryModal(false)
        setSummary("")
      }} centered className="call-summary">
        <Modal.Header closeButton>
          <Modal.Title>Call Summary</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p dangerouslySetInnerHTML={{ __html: summary }}></p>
        </Modal.Body>
      </Modal>
    </div>
  )
}