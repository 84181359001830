import { Col, Container, Row, Button, Form, OverlayTrigger, Tooltip, ListGroup, Modal, Table, ProgressBar } from "react-bootstrap";
import { ThreeDotSpinner } from "../loader";
import { useEffect, useRef, useState } from "react";
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { aiService } from "../../service/api.service";
import swal from "sweetalert";
import { apiUrl } from "../../config/config.js";
import PhoneInput from "react-phone-input-2";
import parsePhoneNumberFromString from "libphonenumber-js";
import CreateAgent from "./CreateAgent.jsx";
import AgentPhone from "./AgentPhone.jsx";
import Voice from "./Voice.jsx";

function Agent() {
  const [loader, setLoader] = useState(false);
  const [agents, setAgents] = useState([])
  const [activeTab, setActiveTab] = useState('blank');
  const [buttonText, setButtonText] = useState('Next');
  const [agentName, setAgentName] = useState("")
  const [createdAgent, setCreatedAgent] = useState({})
  const [createdPhone, setCreatedPhone] = useState({})
  const [templateData, setTemplateData] = useState({ type: "blank", welcomeMsg: "", systemPrompt: "" })
  const [addAgentModal, setAddAgentModal] = useState(false)
  const [selectAgent, setSelectAgent] = useState({})
  const [file, setFile] = useState("")
  const [delAgent, setDelAgent] = useState(0)
  const [callNum, setCallNum] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [buyPhoneNumber, setBuyPhoneNumber] = useState("")
  const [phoneVoice, setPhoneVoice] = useState("")
  const [summaryPrompt, setSummaryPrompt] = useState("You are an expert call evaluator. You will be given a transcript of a call and the system prompt of the AI participant. Determine if the call was successful based on the objectives inferred from the system prompt.")
  const [step, setStep] = useState(0);
  const inputFileRef = useRef();

  const progress = (step / 4) * 100;

  const configValidation = Yup.object().shape({
    welcomeMsg: Yup.string().trim()
      .required('Welcome message is required').test('len', 'Welcome Message cannot exceed 250 characters.', val => val.length < 250),
    systemPrompt: Yup.string().trim().test('len', 'System prompt cannot exceed 3500 characters.', val => val.length < 3500)
      .required('System prompt is required'),
  });

  useEffect(() => {
    getAgents()
  }, [delAgent])

  async function getAgents(selectedId) {
    setLoader(true)
    try {
      let tempAgents = await aiService.getAgents()
      setAgents(tempAgents.data.data)
      setLoader(false)
      if (tempAgents.data.data.length) {
        if (selectedId) {
          setSelectAgent(tempAgents.find(a => a._id == selectedId))
        } else {
          setSelectAgent(tempAgents.data.data[0])
        }
      }
    }
    catch (e) {
      setLoader(false)
    }
  }

  async function createAgent() {
    if (!agentName.trim()) {
      swal({ icon: 'warning', text: 'Please enter agent name.' })
      return;
    } else if (!templateData.welcomeMsg.trim()) {
      swal({ icon: 'warning', text: 'Please enter welcome message for agent.' })
      return;
    } else if (!templateData.systemPrompt.trim()) {
      swal({ icon: 'warning', text: 'Please enter system prompt for agent.' })
      return;
    }
    try {
      setLoader(true)
      let updateData = {
        welcomeMsg: templateData.welcomeMsg,
        systemPrompt: templateData.systemPrompt
      }
      let create = await aiService.createAgent({ create: true, ...updateData, name: agentName })
      setCreatedAgent(create.data.agent)
      setLoader(false)
      setAgentName('')
      // setAddAgentModal(false)
      setActiveTab('blank')
      setButtonText("Buy")
      setStep(step + 1);
      // await getAgents()
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        console.error(error)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  async function deleteAgent(agent_id) {
    if (!agent_id) {
      swal({ icon: 'error', text: "No agent selected", button: "OK" })
      return;
    }
    swal({
      text: "Are you sure you want to delete the agent?",
      showCancelButton: true,
      buttons: ["No", "Yes"],
      icon: "warning",
      dangerMode: true,
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        try {
          setLoader(true)
          await aiService.deleteAgent(agent_id)
          setLoader(false)
          swal({ icon: 'success', text: 'Agent deleted successfully.' })
          setDelAgent(delAgent + 1)
        } catch (error) {
          setLoader(false)
          if (error?.response?.status == 401) {
            swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
              localStorage.clear()
              window.location.href = "/";
            });
          } else {
            swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
          }
        }
      }
    })

  }

  function onFileChangeCapture(e) {
    setFile(e.target.files[0])
  }

  async function addKnowledge(agent_id) {
    if (file) {
      setLoader(true)
      try {
        let form = new FormData()
        form.append('files', file)
        form.append('agent_id', agent_id)
        const add = await aiService.addKnowledgeBase(form)
        swal({ icon: "success", text: "File uploaded." })
        await getAgents()
        setLoader(false)
        setFile(null)
      } catch (e) {
        setLoader(false)
        swal({ icon: 'error', text: e?.response?.data?.message ? e?.response.data.message : e?.message, button: "OK" })
      }
    } else {
      setLoader(false)
      swal({ icon: 'error', text: 'Please upload a file.' })
      return;
    }
  }

  async function clearKnowledge() {
    swal({
      text: "Are you sure you want to clear the knowledge base of agent?",
      showCancelButton: true,
      icon: "warning",
      buttons: ["No", "Yes"]
    }).then(async (isConfirmed) => {
      if (isConfirmed) {
        setLoader(true)
        try {
          const remove = await aiService.clearKnowledgeBase({ "agent_id": selectAgent._id })
          swal({ icon: "success", text: "Knowledge base cleared." })
          setSelectAgent({ ...selectAgent, knowledge: [] })
          setLoader(false)
        } catch (e) {
          setLoader(false)
          swal({ icon: 'error', text: e?.response?.data?.message ? e?.response.data.message : e?.message, button: "OK" })
          return;
        }
      }
    })
  }

  async function makeCall() {
    try {
      const callNumber = parsePhoneNumberFromString(phoneNumber);
      if (!callNumber.isValid()) {
        swal({ icon: "warning", text: "Kindly enter a valid phone number." })
        return;
      }
      setLoader(true)
      await aiService.makeCall({ phone: callNum, phone_id: selectAgent.phone_id, agent: selectAgent._id })
      setLoader(false)
      setCallNum("")
      setPhoneNumber("")
      swal({ icon: "success", text: "Call created successfully." })
    } catch (error) {
      console.log(error)
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      }
    }
  }

  const nextStep = async () => {
    if (step === 0) {
      await createAgent()
    } else if (step === 1) {
      await buyNumber()
    } else if (step === 2) {
      if (!phoneVoice) {
        swal({ icon: 'warning', text: 'Kindly select a voice for the phone agent.' })
        return;
      }
      try {
        setLoader(true)
        await aiService.updatePhoneNumber({ id: createdPhone._id, voice: phoneVoice })
        setLoader(false)
        setStep(step + 1);
    } catch (err) {
        setLoader(false)
      }
    } else if (step === 3) {
      if (!summaryPrompt) {
        swal({ icon: 'warning', text: 'Kindly enter summary prompt for call analysis.' })
        return;
      }
      try {
        setLoader(true)
        await aiService.updatePhoneNumber({ id: createdPhone._id, agent: createdAgent._id, analysis:summaryPrompt })
        setLoader(false)
        setAddAgentModal(false)
        await getAgents()
      } catch (err) {
        setLoader(false)
      }
    }
  };

  async function buyNumber() {
    if (!buyPhoneNumber.trim()) {
      swal({ icon: 'warning', text: 'Please select a phone number.' })
      return;
    }
    try {
      setLoader(true)
      const response = await aiService.buyPhoneNumber({ phone: buyPhoneNumber, agent: createdAgent._id })
      setCreatedPhone(response.data.data)
      setButtonText("Next")
      setStep(step + 1);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        console.error(error)
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }
  }

  return (
    <div className="dashboard-main-area">
      {loader ? <ThreeDotSpinner /> : ""}
      <Container fluid>
        <div className="dashboard-area-heading management-heading">
          <Row className="row mb-3">
            <div className="col-12">
              <h2 className="align-self-center m-0">Agent Management <img src={require("../../assets/images/icons8-user.gif")} /></h2>
            </div>
          </Row>
          <Row className="pb-4">
            <Col lg={4} xxl={3} className="ps-0">
              <div className="availabilit-section">

                <Button variant="unset" className="create-agent" onClick={() => setAddAgentModal(true)}>
                  <span style={{ fontWeight: "bold", fontSize: "15px" }}>Create Agent</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-1" viewBox="0 0 16 16">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
                  </svg>
                </Button>
                {
                  agents.length
                    ?
                    <div className="mt-3">
                      <ListGroup activeKey={`#${selectAgent._id}`}>
                        {
                          agents.map((a, i) => (
                            <ListGroup.Item action className="mb-2 agent-list-name d-flex justify-content-between align-items-center" href={"#" + a._id} onClick={() => {
                              setSelectAgent(a)
                              setFile(null)
                            }}>
                              {a.name}
                              <Button size="sm" variant="danger" onClick={() => deleteAgent(a._id)}>
                                <svg className="text-danger" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                  <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                </svg>
                              </Button>
                            </ListGroup.Item>
                          ))
                        }
                      </ListGroup>
                    </div>
                    : ""
                }
              </div>
            </Col>
            <Col lg={8} xxl={9} className="ps-0">
              <Row>
                {console.log(createdAgent)}
                <Col md={12}>
                  <div className="availabilit-section input-w-100 agent-settings-area">
                    {
                      selectAgent?.name ?
                        <>
                          <div className="d-flex justify-content-between">
                            <h3>Agent Settings</h3>
                            <div className="">
                              {
                                selectAgent?.phone ?
                                  <>
                                    <div className="d-flex justify-content-end">
                                      <h6>Phone: &nbsp;</h6>
                                      <h6>{selectAgent.phone}</h6>
                                    </div>
                                    <div className="d-flex">
                                      <PhoneInput
                                        country={'us'}
                                        inputClass="w-100"
                                        value={phoneNumber}
                                        onChange={(phone, c, e, value) => {
                                          setCallNum(phone)
                                          setPhoneNumber(value)
                                        }}
                                        inputProps={{
                                          name: 'phoneNumber',
                                          required: true,
                                          autoFocus: true,
                                        }}
                                      />
                                      <Button className="px-3 ms-2" variant="success" size="sm" onClick={() => makeCall()}>Call</Button>
                                    </div>
                                  </>
                                  : ""
                              }
                            </div>
                          </div>
                          <Formik
                            enableReinitialize={true}
                            initialValues={{
                              name: selectAgent?.name ? selectAgent.name : '',
                              welcomeMsg: selectAgent?.welcomeMsg ? selectAgent.welcomeMsg : '',
                              systemPrompt: selectAgent?.systemPrompt ? selectAgent.systemPrompt : ''
                            }}
                            validationSchema={configValidation}
                            onSubmit={async (values, { setSubmitting }) => {
                              setSubmitting(false);
                              try {
                                setLoader(true)
                                await aiService.createAgent({ ...values, agent_id: selectAgent._id })
                                setLoader(false)
                                swal({ icon: "success", text: "Updated successfully." })
                                await getAgents(selectAgent._id)
                              } catch (err) {
                                setLoader(false)
                              }

                            }}
                          >
                            {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting
                            }) => {
                              return (
                                <Form onSubmit={handleSubmit}>

                                  <Row>
                                    <Col md="12">
                                      <Form.Group>
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                          className="w-100"
                                          name="name"
                                          placeholder="The name of the agent."
                                          value={values.name}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.name && !!errors.name}
                                        />
                                        <ErrorMessage name="name" component="div" className="text-danger" />
                                      </Form.Group>
                                      <Form.Group className="mt-3">
                                        <Form.Label>Welcome Message
                                          <OverlayTrigger
                                            // placement="right"
                                            delay={{ show: 100, hide: 400 }}
                                            overlay={<Tooltip>
                                              The first message that the assistant will say.
                                            </Tooltip>}
                                          >
                                            <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                            </svg>
                                          </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control
                                          className="w-100"
                                          name="welcomeMsg"
                                          placeholder="The first message that the assistant will say."
                                          value={values.welcomeMsg}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.welcomeMsg && !!errors.welcomeMsg}
                                        />
                                        {/* <Field type="text" name="welcomeMsg" id="welcomeMsg" className="form-control" /> */}
                                        <ErrorMessage name="welcomeMsg" component="div" className="text-danger" />
                                      </Form.Group>
                                    </Col>

                                    <Col md={12}>
                                      <Form.Group className="mt-3">
                                        <Form.Label>System Prompt
                                          <OverlayTrigger
                                            delay={{ show: 100, hide: 400 }}
                                            overlay={<Tooltip>
                                              The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant.
                                            </Tooltip>}
                                          >
                                            <svg className="mx-1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2" />
                                            </svg>
                                          </OverlayTrigger>

                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={10}
                                          className="w-100 textarea"
                                          name="systemPrompt"
                                          placeholder="The system prompt can be used to configure the context, role, personality, instructions and so on for the assistant."
                                          value={values.systemPrompt}
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          isInvalid={touched.systemPrompt && !!errors.systemPrompt}
                                        />
                                      </Form.Group>
                                      <ErrorMessage name="systemPrompt" component="div" className="text-danger" />

                                    </Col>
                                  </Row>

                                  <Button type="submit" className="mt-4 btn-success px-4" disabled={isSubmitting}>
                                    Submit
                                  </Button>
                                </Form>
                              )
                            }}
                          </Formik>

                        </>
                        :
                        <div className="create-agent-area">
                          <img src={require("../../assets/images/create-user.gif")} />
                          <h5 className="text-center">Kindly create/select an Agent.</h5>
                        </div>

                    }
                  </div>
                </Col>
                <Col md={12} className="mt-3">
                  {
                    selectAgent?.name ?
                      <>
                        <div className="availabilit-section knowledge-base-section">
                          <div className="d-flex justify-content-between">
                            <h3>Knowledge Base</h3>
                            <div className="knowledge-base-right">
                              <input
                                className="d-none"
                                type="file"
                                ref={inputFileRef}
                                accept=".pdf,.doc,.docx,.txt"
                                onChangeCapture={onFileChangeCapture}
                              />
                              <Button className="btn-success" onClick={() => {
                                inputFileRef.current.click();
                              }}>
                                <i class="fa fa-plus" aria-hidden="true"></i> Add File
                              </Button>

                              {
                                file ?
                                  <div className="pdf-view">
                                    <h5>{file.name}</h5>
                                    <Button onClick={() => addKnowledge(selectAgent._id)}>
                                      <i className="fa fa-upload me-2" aria-hidden="true"></i>
                                      Upload
                                    </Button>
                                  </div>
                                  : ""
                              }
                              {
                                selectAgent.knowledge.length ?
                                  <Button variant="danger" size="sm" className="align-items-center d-flex delete-all-btns" onClick={() => clearKnowledge()}>
                                    <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3-fill" viewBox="0 0 16 16">
                                      <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5m-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5M4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06m6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528M8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5" />
                                    </svg>
                                    Delete all
                                  </Button>
                                  : ""
                              }
                            </div>

                          </div>

                          <div className="table-outer-box">
                            {
                              selectAgent.knowledge.length ?
                                <Table responsive="sm" className="UserListTable">
                                  <thead>
                                    <tr>
                                      <th>File Name</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      selectAgent.knowledge?.map(r => (
                                        <tr>
                                          <td>
                                            <a href={`${apiUrl}/media/${r.url}`} target="_blank" className="text-dark">{r.filename}</a>
                                          </td>
                                          <td>
                                            <span className="done-btn pending-btn processing-btn">
                                              {r.status}
                                            </span></td>
                                        </tr>
                                      ))
                                    }
                                  </tbody>
                                </Table>
                                :
                                <div className="no-files-added">
                                  <img src={require("../../assets/images/no-data.gif")} />
                                  <h6 className="text-center pt-3">No files added</h6>
                                </div>
                            }
                          </div>
                        </div>
                      </>
                      :
                      ""
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      <Modal show={addAgentModal} onHide={() => setAddAgentModal(false)} className="import-phone-modal create-agent-modal" centered backdrop="static">
        <Modal.Header>
          <Modal.Title>
            Create New Agent
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{maxHeight:"600px", overflowY:"scroll"}}>
          <ProgressBar now={progress} label={`${Math.round(progress)}%`} className="mb-4" />
          {
            step === 0
              ?
              <CreateAgent activeTab={activeTab} setActiveTab={setActiveTab} templateData={templateData} setTemplateData={setTemplateData} agentName={agentName} setAgentName={setAgentName} />
              :
              step === 1
                ?
                <AgentPhone setLoader={setLoader} selectedNumber={buyPhoneNumber} setSelectedNumber={setBuyPhoneNumber} />
                :
                step === 2
                ?
                  <Voice phoneVoice={phoneVoice} setPhoneVoice={setPhoneVoice} />
                :
                step === 3
                  ?
                  <>
                    <label htmlFor="id_asis">Summary</label>
                    <Form.Control
                      as={"textarea"}
                      className="w-100 textarea"
                      rows={4}
                      placeholder={summaryPrompt}
                      value={summaryPrompt}
                      onChange={(e) => setSummaryPrompt(e.target.value)}
                    />
                    <small>This is the prompt that's used to summarize the call. You can find the summary in the Call List Page.</small>
                  </>
                  : ""
          }
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-success" onClick={() => nextStep()}>{buttonText}</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default Agent