import { useEffect, useState } from "react"
import { aiService } from "../../service/api.service"
import swal from "sweetalert";
import { Form, Spinner, Table } from "react-bootstrap";

function AgentPhone({selectedNumber, setSelectedNumber}) {
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    getAvailablePhoneNumbers()
  }, [])

  async function getAvailablePhoneNumbers() {
    try {
      let response = await aiService.getAvailablePhoneNumbers()
      setNumbers(response.data.data)
    } catch (error) {
      if (error?.response?.status == 401) {
        swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
          localStorage.clear()
          window.location.href = "/";
        });
      } else {
        swal({ icon: 'error', text: error?.response?.data?.message ? error?.response?.data?.message : error?.message, button: "OK" })
      }
    }

  }


  return (
    <>
      {
        numbers.length ?
          <>
            <p>Select a number to buy to use with AI Office agent.</p>
            <Table>
              <thead>
                <tr>
                  <th>Phone Number</th>
                  <th>Price (Monthly fee)</th>
                </tr>
              </thead>
              <tbody>
                {
                  numbers.map(n => (
                    <tr onClick={() => setSelectedNumber(n.phone)}>
                      <td>
                        <div className="d-flex">
                          <Form.Check
                            checked={selectedNumber == n.phone}
                            name="agentPhone"
                            type="radio"
                            className="me-2"
                          />
                          <h6>{n.phone}</h6>
                        </div>
                      </td>
                      <td>
                        <h6>$5</h6>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </>
          :
          <p className="d-flex align-items-center justify-content-center">
            <Spinner animation="border" role="status" className="me-2"></Spinner>
            Please wait, while loading phone numbers...
          </p>
      }
    </>
  )
}

export default AgentPhone